<template>
    <div>
        <van-tabs v-model:active="active" @change="onChange">
            <van-tab title="全部"></van-tab>
            <van-tab title="进行中"></van-tab>
            <van-tab title="已结束"></van-tab>
        </van-tabs>
        <van-list
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="m-list">
                <div class="a-li" @click="toUrl('activityDetails', item.hash_id)" v-for="item in list" :key="item.id">
                    <img :src="item.image" />
                    <span class="title">{{item.title}}</span>
                    <span class="price">￥{{item.price}} <em v-if="item.line_price != null">￥{{item.line_price}}</em></span>
                    <span class="see">查看详情</span>
                </div>
            </div>
        </van-list>
        <Footer />
        <WxNoShare />
    </div>
</template>

<script>
    import { Tab, Tabs, List } from 'vant';
    export default {
        components: {
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [List.name]: List
        },
        name: "activity",
        data() {
            return {
                active: 0,
                loading: false,
                finished: true,
                list : []
            }
        },
        created () {
            //this.onLoad();
        },
        methods: {
            onLoad () {
                this.$api.get('activity/index', {type: this.active, mid: sessionStorage.getItem('mid')}, r=>{
                    r.data.data.map(item =>{
                        this.list.push(item)
                    });
                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (this.list.length >= r.data.total) {
                        this.finished = true;
                    }
                })
            },
            toUrl (name, id) {
                this.$router.push({name: name, query: {id: id, mid: sessionStorage.getItem('mid')}})
            },
            onChange() {
                this.list = [];
                this.onLoad();
            }
        }
    }
</script>

<style scoped>
    .m-list{
        overflow: hidden;
    }
    .a-li{
        background: #ffffff;
        margin:10px;
        text-align: center;
        font-size: 16px;
        padding:10px;
        border-radius: 2px;
        width: 39%;
        float: left;
    }
    .a-li img{
        width: 100%;
    }
    .a-li .title{
        display: block;
        font-size: 14px;
        text-align: left;
        line-height: 30px;
    }
    .a-li .price{
        font-size: 15px;
        color:#EE0A24;
        font-weight: bold;
        text-align: left;
        display: block;
        margin-bottom: 5px;
    }
    .a-li .price em{
        font-style: normal;
        font-size: 12px;
        font-weight: normal;
        color:#A2A1A3;
        text-decoration: line-through;
    }
    .a-li .see{
        display: block;
        font-size: 14px;
        background: #EE0A24;
        color:#ffffff;
        border-radius: 20px;
        line-height: 27px;
    }
</style>
