<template>
  <div v-if="userInfo.status == 1">
    <van-tabs v-model:active="active">
        <van-tab title="今日送货上门" to="/shop/index"></van-tab>
        <van-tab title="送货上门记录" to="/shop/list"></van-tab>
    </van-tabs>
    <div class="statistics">
      今日共送货上门<span>{{ statistics.dayCount }}</span>件
    </div>
    <van-sticky>
      <van-tabs v-model:active="lhActive" @change="onChangeNum">
        <van-tab v-for="index in lhnum" :key="index" :title="index"></van-tab>
      </van-tabs>
    </van-sticky>
    
    <!--
    <div class="scan-btn">
      <van-uploader
        :before-read="beforeRead"
        :after-read="afterRead"
      >
        <van-button icon="plus" type="primary" size="small" class="manual">开始拍照上传照片</van-button>
      </van-uploader>
    </div>
    -->
    <div class="shop-main">
      <div class="kd-list">
        <ul>
          <van-checkbox-group v-model="shopChecked">
            <li v-for="item in shopList" :key="item.id">
              <div class="kd-li">
                <img :src="item.pic" class="kd-pic" @click="openImg(item.thumbnail)" />
                <span class="nickname"><van-tag plain type="primary">{{ item.user.nickname }}</van-tag></span>
                <div class="short-name"><van-tag type="primary">{{ item.shop.shortName }}</van-tag></div>
                <div class="info">
                  <span class="time">{{ item.addTime }}</span>
                </div>
              </div>
              <div class="box-li"><van-checkbox :name="item.id" shape="square">ID：{{ item.id }}</van-checkbox></div>
            </li>
          </van-checkbox-group>
        </ul>
      </div>
    </div>
    <van-submit-bar button-text="提交" @submit="onSubmit" button-color="#1989FA">
      <span style="position: absolute;left:3%;">共选中 {{ shopChecked.length }} 个</span>
    </van-submit-bar>

    <van-pagination v-if="totalItems > 30" v-model="currentPage" :total-items="totalItems" :items-per-page="perPage" @change="changePage" force-ellipses />
    <!-- <Footer /> -->
    <div style="height: 60px;"></div>
    <WxNoShare />
  </div>
  <div v-else>
    <van-empty description="未授权，请联系管理员" />
  </div>
</template>

<script>
  import { ref, getCurrentInstance, onMounted } from 'vue';
  import { Tab, Tabs, List, Pagination, Search, Popup, Form, Field, CellGroup, Tag, Uploader, Toast, ImagePreview, Dialog, Empty, Sticky, Checkbox, CheckboxGroup, SubmitBar } from 'vant';
  import axios from 'axios';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  // import wx from 'weixin-jsapi';
  import Compressor from 'compressorjs';
  import Cookies from "js-cookie"

  export default {
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Tag.name]: Tag,
      [List.name]: List,
      [Pagination.name]: Pagination,
      [Search.name]: Search,
      [Popup.name]: Popup,
      [Form.name]: Form,
      [Field.name]: Field,
      [CellGroup.name]: CellGroup,
      [Uploader.name]: Uploader,
      [Toast.name]: Toast,
      [Empty.name]: Empty,
      [ImagePreview.name]: ImagePreview,
      [Sticky.name]: Sticky,
      [Checkbox.name]: Checkbox,
      [CheckboxGroup.name]: CheckboxGroup,
      [SubmitBar.name]: SubmitBar,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const store = useStore();
      const storeUserInfo = ref(store.state);
      const statistics = ref({ shop: {dayCount: 0, sumCount: 0} });
      const active = ref(0);
      const lhActive = ref(0);
      const shopList = ref([]);
      const currentPage = ref(1);
      const totalItems = ref(0);
      const perPage = ref(5);
      const lhnum = ref(['1号楼', '2号楼', '3号楼', '4号楼', '5号楼', '6号楼', '7号楼', '7-1号楼', '8号楼', '9号楼', '10号楼', '11号楼', '12号楼', '13号楼', '14号楼', '15号楼', '16号楼', '17号楼', '18号楼', '19号楼', '20号楼', '21号楼', '22号楼', '23号楼', '24号楼', '25号楼', '26号楼', '27号楼', '28号楼', '29号楼', '30号楼', '31号楼', '32号楼', '33号楼', '34号楼', '35号楼', '36号楼', '37号楼', '38号楼', '39号楼', '40号楼', '41号楼', '42号楼', '43号楼', '商铺']);
      const fileList = ref([]);
      const thumbnail = ref([]);
      const buildingNo = ref(1);
      const locType = ref(1);
      const userInfo = ref([]);
      const shopChecked = ref([]);
      const router = useRouter();
      //const rtype = router.currentRoute.value.query.type;
      //const clothesNo = router.currentRoute.value.query.no;

      console.log(router.currentRoute.value);

      onMounted(() => {
        getStatistics();
        onLoad();
        getUserInfo();
      });

      //查看图片
      function openImg(url) {
        ImagePreview({
          //images的数据结构是 [ 'yoururl','yoururl',]
          images: [url],
          //closeable展示关闭的小图标
          closeable: true,
          //startPosition: 1, // 图片起始位置
        });
        //showImagePreview([url]);
      }

      //统计数据
      function getStatistics() {
        proxy.$api.get('photos/data', {}, r=>{
          statistics.value = r.result;
        })
      }

      //加载数据
      function onLoad() {
        proxy.$api.get('photos/index', {page: currentPage.value, pageSize: 90, buildingNo: buildingNo.value, locType: locType.value}, r=>{
          let res = r.result;
          shopList.value = res.items;
          currentPage.value = res.currentPage;
          totalItems.value = res.total;
          perPage.value = res.perPage;
          setExpress();
        })
      }

      //设置选中状态
      function setExpress() {
        shopList.value.map((item) => {
          if(item.status == 1){
            if(!shopChecked.value.includes(item.id)){
              shopChecked.value.push(item.id);
            }
          }
        });
      }

      //选择楼号
      function onChangeNum(e) {
        if(e == 7) {
          buildingNo.value = '7-1';
        } else if(e > 7) {
          buildingNo.value = e;
        } else {
          buildingNo.value = e + 1;
        }
        Cookies.set('buildingNo', buildingNo.value, { expires: 7 });
        //console.log('bbbbb');
        onLoad();
      }

      //获取用户信息
      function getUserInfo(){
        proxy.$api.get('account/info', {}, r=>{
          userInfo.value = r.result;
          console.log('userInfo', userInfo.value);
        })
      }

      //翻页
      function changePage(e) {
        currentPage.value = e;
        onLoad();
      }

      //图片压缩
      function ImageCompressor(file, backType, quality) {
        return new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: quality || 0.6, //压缩质量
            success(result) {
              if (!backType || backType == 'blob') {
                resolve(result);
              } else if (backType == 'file') {
                resolve(file);
              } else {
                resolve(file);
              }
              // resolve(result);
            },
            error(err) {
              console.log('图片压缩失败');
              reject(err);
            },
          });
        });
      }

      const beforeRead = (file) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          if (file.type == 'image/jpeg' || file.type == 'image/png') {
            // compressorjs 默认开启 checkOrientation 选项
            // 会将图片修正为正确方向
            new Compressor(file, {
              success: resolve,
              error(err) {
                console.log(err.message);
              },
            });
            await ImageCompressor(file, 'file', 0.6); //图片压缩
          } else {
            Toast('请上传 jpg, png 格式的图片');
            return false;
          }
        });

      const afterRead = async (file) => {
        if(userInfo.value.status == 0){
          Toast('账号审核未通过，请联系管理');
          return false;
        }

        file.status = 'uploading';
        file.message = '上传中...';
 
        Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "图片上传中..."
        });
        let formData = new FormData();
        formData.append('file', new Blob([file.file])); //转换成二进制流
        formData.append('lnum', buildingNo.value);
        formData.append('lh_type', 0);
        formData.append('uid', storeUserInfo.value.uid);
        axios.post(process.env.VUE_APP_API_URL + 'upload/photo', formData).then(function (res) {
          let data = res.data
          file.status = 'done';
          file.message = '上传完成';
          thumbnail.value.push(data.url);
          Toast.clear();
          onLoad();
          getStatistics();
        }).catch(function (error) {
          file.status = 'failed';
          file.message = '上传失败';
          console.log(error);
        });
      };

      //上传前多张图片处理
      const beforeReadBatch = (file) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          console.log('file', file);
          if(Array.isArray(file)){
            // eslint-disable-next-line no-unused-vars
            file.forEach(async (item, index)=>{
              if (item.type == 'image/jpeg' || item.type == 'image/png') {
                // compressorjs 默认开启 checkOrientation 选项
                // 会将图片修正为正确方向
                new Compressor(item, {
                  success: resolve,
                  error(err) {
                    console.log(err.message);
                  },
                });
                return await ImageCompressor(item, 'file', 0.6); //图片压缩
              } else {
                Toast('请上传 jpg, png 格式的图片');
                return false;
              }
            })
          }else{
            if (file.type == 'image/jpeg' || file.type == 'image/png') {
              // compressorjs 默认开启 checkOrientation 选项
              // 会将图片修正为正确方向
              new Compressor(file, {
                success: resolve,
                error(err) {
                  console.log(err.message);
                },
              });
              await ImageCompressor(file, 'file', 0.6); //图片压缩
            } else {
              Toast('请上传 jpg, png 格式的图片');
              return false;
            }
          }
        });

      //多张图片开始上传
      const afterReadBatch = (file) => {
        //这里需要判断一下用户是单张上传多次 还是多张单次上传
        //如果是他是多张单次上传的操作的话  这边接收到的回调是一个数组类型 因此要判断它
				if(Array.isArray(file)){
          //这次真是要吐槽一下vant组件 真心没有element好用  
          //element 上传地址可以直接写到组件里 vant还要在方法里写上传的接口地址
					// eslint-disable-next-line no-unused-vars
					file.forEach((item, index)=>{
            Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: "图片上传中..."
            });

            let f2 = ImageCompressor(item, 'file', 0.6); //图片压缩
            console.log(f2);

            let formData = new FormData();
            formData.append('file', new Blob([item.file])); //转换成二进制流
            formData.append('lnum', buildingNo.value);
            formData.append('lh_type', 0);
            formData.append('uid', storeUserInfo.value.uid);
            axios.post(process.env.VUE_APP_API_URL + 'upload/photo', formData).then(function (res) {
              let data = res.data
              item.status = 'done';
              item.message = '上传完成';
              thumbnail.value.push(data.url);
              Toast.clear();
              onLoad();
              getStatistics();
            }).catch(function (error) {
              item.status = 'failed';
              item.message = '上传失败';
              console.log(error);
            });
					})
				}else{
          file.status = 'uploading';
          file.message = '上传中...';
          Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: "图片上传中..."
          });
          let formData = new FormData();
          formData.append('file', new Blob([file.file])); //转换成二进制流
          formData.append('lnum', buildingNo.value);
          formData.append('lh_type', 0);
          formData.append('uid', storeUserInfo.value.uid);
          axios.post(process.env.VUE_APP_API_URL + 'upload/photo', formData).then(function (res) {
            let data = res.data
            file.status = 'done';
            file.message = '上传完成';
            thumbnail.value.push(data.url);
            Toast.clear();
            onLoad();
            getStatistics();
          }).catch(function (error) {
            file.status = 'failed';
            file.message = '上传失败';
            console.log(error);
          });
					return true;
				}
			}

      const beforeDelete = (file) => {
        let newThumbnail = [];
        thumbnail.value.map((item) => {
          if (item != file.url) {
            newThumbnail.push(item);
          }
        });
        thumbnail.value = newThumbnail;
        return true;
      };

      function onSubmitUpload(values) {
        try {
          delete values.photo;
          if (!values.remark && thumbnail.value.length == 0) {
            Toast('备注信息、照片必须输入上传一个才可提交！');
            return;
          }
          if (thumbnail.value.length > 0 && fileList.value.length > 0) {
            values.thumbnail = thumbnail.value;
          }
          let url = 'laundrys/updateOther';
          proxy.$api.post(url, values, res=>{
            Toast(res.result.msg);
          }) 
        // eslint-disable-next-line no-empty
        } finally {}
      }

      //删除
      function deletePhoto(item){
        if(item.userId == userInfo.value.id || userInfo.value.id == 27){
          Dialog.confirm({
            title: '消息',
            message: '是否确认要删除？',
          })
            .then(() => {
              proxy.$api.get('photos/destroy', {id: item.id}, r=>{
                let res = r.result;
                console.log(res);
                getStatistics();
                onLoad();
              })
            })
            .catch(() => {
              // on cancel
            });
        }else{
          Toast('你只可删除自己上传的图片！');
          return;
        }
      }

      // 设置面单状态
      function selectShop(record) {
        let status = 0;
        shopChecked.value.map((item)=>{
          if(item == record.id){
            status = 1;
          }
        })
        proxy.$api.post('photos/update', {id: record.id, status: status, buildingNo: buildingNo.value}, r=>{
          let res = r.result;
          shopChecked.value = res.ids;
        })
        proxy.$api.post('photos/update', {ids: [{id: record.id, status: status}]}, r=>{
          let res = r.result;
          shopChecked.value = res.ids;
        })
      }

      // 提交表单
      function onSubmit() {
        let arr = [];
        let status = 0;
        shopList.value.map((i) => {
          status = 0;
          shopChecked.value.some((j)=>{
            if(i.id == j){
              status = 1;
              return true
            }
          })
          arr.push({id: i.id, status: status});
        });
        proxy.$api.post('photos/update', {data: arr, buildingNo: buildingNo.value}, r=>{
          let res = r.result;
          shopChecked.value = res.ids;
          Toast('提交成功');
        })
      }

      return {
        active,
        statistics,
        onChangeNum,
        shopList,
        currentPage,
        totalItems,
        perPage,
        changePage,
        lhnum,
        lhActive,
        fileList,
        onSubmitUpload,
        onSubmit,
        beforeRead,
        afterRead,
        beforeDelete,
        afterReadBatch,
        beforeReadBatch,
        openImg,
        deletePhoto,
        userInfo,
        shopChecked,
        selectShop,
      };
    },
  };
</script>
<style scoped>
  .statistics {
    overflow: hidden;
    font-size: 13px;
    text-align: center;
    padding: 10px 0;
    background-color: #e6f7ff;
  }
  .statistics span {
    font-weight: bold;
    display: inline-block;
    margin: 0 5px;
    color: #1989fa;
  }
  .scan-btn {
    padding: 10px 15px;
    background-color: #fff;
    text-align: center;
  }
  .shop-main {
    padding: 0 0 10px;
    overflow: hidden;
    background-color: #fff;
  }
  .shop-main .th {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #f5f6f7;
    line-height: 30px;
  }
  .shop-main .tr {
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    border-bottom: 1px solid #f5f6f7;
  }
  .shop-main .tr:last-child {
    border-bottom: none;
  }
  .shop-main .tr:nth-child(2n-1) {
    background: #fafafa;
  }
  .manual {
    margin-left: 15px;
  }
  .popup-manual {
    border-radius: 3px;
    width: 300px;
    padding: 15px 0;
  }
  .kd-list ul li {
    float: left;
    width: 33.33%;
    margin-bottom: 2%;
  }
  .kd-list ul li .kd-li{
    position: relative;
  }
  .kd-list ul li .kd-li .num {
    position: absolute;
    top: 1%;
    left: 2%;
  }
  .kd-list ul li .kd-li .kd-pic {
    width: 96%;
    margin: 2% 2% 0;
    height: 150px;
  }
  .kd-list ul li .kd-li .nickname {
    position: absolute;
    top: 1%;
    right: 2%;
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;
    word-wrap: break-word;
  }
  .kd-list ul li .close {
    position: absolute;
    top: 1%;
    right: 2%;
    font-size: 15px;
    background: #fff;
  }
  .kd-list ul li .kd-li .info {
    position: absolute;
    bottom: 0;
    left: 2%;
    width: 96%;
    background-color: #000;
    opacity: .8;
    text-align: center;
    padding: 3px 0;
    color:#fff;
  }
  .kd-list ul li .kd-li .short-name{
    position: absolute;
    left: 2%;
    bottom: 20px;
  }
  .kd-list ul li .box-li{
    margin-top:2%;
    padding: 0 2%;
  }
</style>
