<template>
  <div>
    <div class="f-h"></div>
    <div class="footer">
      <van-tabbar v-model="active">
        <van-tabbar-item icon="apps-o" @click="toUrl('manage', 0)">工作台</van-tabbar-item>
        <van-tabbar-item icon="shop-o" @click="toUrl('shop', 1)">驿站</van-tabbar-item>
        <van-tabbar-item icon="gift-o" @click="toUrl('activity', 2)">团购</van-tabbar-item>
        <van-tabbar-item icon="manager-o" @click="toUrl('user', 3)">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
    export default {
      data () {
        return {
          active: 0
        }
      },
      created () {
        let routeName = this.$route.name
        if (routeName == 'manage') {
          this.active = 0
        }else if (routeName == 'shop' || routeName == 'shopList') {
          this.active = 1
        }else if (routeName == 'activity') {
          this.active = 2
        }else if (routeName == 'user' || routeName == 'UsersSetting' || routeName == 'UsersEdit') {
          this.active = 3
        }
      },
      methods: {
          toUrl (name, type) {
            this.active = type
            this.$router.push({name: name})
          }
      }
    }
</script>

<style scoped>

</style>
