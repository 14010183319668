<template>
  <div>
    <van-sticky>
      <van-dropdown-menu ref="menuRef">        
        <van-dropdown-item v-model="storeId" :options="options" @change="changeLocType" />
        <van-dropdown-item v-model="buildingNo" :options="lhnumList" @change="changeLocType" />
        <van-dropdown-item title="日期筛选" ref="itemRef">
          <van-field
              v-model="localDate"
              is-link
              readonly
              label="日期选择"
              name="birthday"
              placeholder="点击选择日期"
              @click="showPicker = true"
            />
            <van-popup v-model:show="showPicker" round position="bottom">
              <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirmDate"
                @cancel="showPicker = false"
              />
            </van-popup>
          <div style="padding: 5px 16px;">
            <van-button type="primary" block round @click="onConfirm">
              确认
            </van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
      <!--
      <div class="statistics">
        总共送货上门<span>{{ statistics.sumCount }}</span>件
      </div>
      -->
      <!-- <van-tabs v-model:active="lhActive" @change="onChangeNum">
        <van-tab v-for="index in lhnum" :key="index" :title="index"></van-tab>
      </van-tabs> -->
    </van-sticky>

    <div class="shop-main">
      <div class="kd-list">
        <ul>
          <van-checkbox-group v-model="shopChecked">
            <li v-for="item in shopList" :key="item.id">
              <div class="kd-li">
                <img :src="item.pic" class="kd-pic" />
                <span class="nickname"><van-tag plain type="primary">{{ item.user.nickname }}</van-tag></span>
                <div class="short-name"><van-tag type="primary">{{ item.shop.shortName }}</van-tag></div>
                <div class="building-tag"><van-tag type="primary">{{ item.buildingNo+'号楼' }}</van-tag></div>
                <div class="info">
                  <span class="time">{{ item.addTime }}</span>
                </div>
              </div>
              <div class="box-li"><van-checkbox :name="item.id" shape="square">ID：{{ item.id }}</van-checkbox></div>
            </li>
          </van-checkbox-group>
        </ul>
      </div>
    </div>
    <van-submit-bar button-text="提交" @submit="onSubmit" button-color="#1989FA" style="z-index: 9;">
      <!-- <span style="position: absolute;left:3%;">共选中 {{ shopChecked.length }} 个</span> -->
    </van-submit-bar>

    <van-pagination v-if="totalItems > 30" v-model="currentPage" :total-items="totalItems" :items-per-page="perPage" @change="changePage" force-ellipses />
    <!-- <Footer /> -->
    <div style="height: 60px;"></div>
    <WxNoShare />
  </div>
</template>

<script>
  import { ref, getCurrentInstance, onMounted } from 'vue';
  import { Tab, Tabs, List, Pagination, Search, Popup, Form, Field, CellGroup, Tag, Uploader, Toast, ImagePreview, Sticky, Checkbox, CheckboxGroup, SubmitBar,DropdownMenu, DropdownItem } from 'vant';
  //import axios from 'axios';
  //import { useStore } from 'vuex';
  // import wx from 'weixin-jsapi';

  export default {
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Tag.name]: Tag,
      [List.name]: List,
      [Pagination.name]: Pagination,
      [Search.name]: Search,
      [Popup.name]: Popup,
      [Form.name]: Form,
      [Field.name]: Field,
      [CellGroup.name]: CellGroup,
      [Uploader.name]: Uploader,
      [Toast.name]: Toast,
      [ImagePreview.name]: ImagePreview,
      [Sticky.name]: Sticky,
      [Checkbox.name]: Checkbox,
      [CheckboxGroup.name]: CheckboxGroup,
      [SubmitBar.name]: SubmitBar,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      //const store = useStore();
      //const userInfo = ref(store.state);
      const statistics = ref({ shop: {dayCount: 0, sumCount: 0} });
      const active = ref(1);
      const lhActive = ref(0);
      const shopList = ref([]);
      const currentPage = ref(1);
      const totalItems = ref(0);
      const perPage = ref(5);
      const lhnum = ref(['1号楼', '2号楼', '3号楼', '4号楼', '5号楼', '6号楼', '7号楼', '7-1号楼', '8号楼', '9号楼', '10号楼', '11号楼', '12号楼', '13号楼', '14号楼', '15号楼', '16号楼', '17号楼', '18号楼', '19号楼', '20号楼', '21号楼', '22号楼', '23号楼', '24号楼', '25号楼', '26号楼', '27号楼', '28号楼', '29号楼', '30号楼', '31号楼', '32号楼', '33号楼', '34号楼', '35号楼', '36号楼', '37号楼', '38号楼', '39号楼', '40号楼', '41号楼', '42号楼', '43号楼', '商铺']);
      const buildingNo = ref('0');
      const locType = ref(0);
      const currentDate = ref(new Date());
      const localDate = ref('');
      const userId = ref('');
      const showPicker = ref(false);
      const shopChecked = ref([]);

      const storeId = ref(0);
      const menuRef = ref(null);
      const itemRef = ref(null);
      const switch1 = ref(false);
      const switch2 = ref(false);
      const options = [
        { text: '全部驿站', value: 0 },
        { text: '兔喜驿站', value: 1 },
        { text: '邻里驿站', value: 2 },
        { text: '兔喜圆通', value: 3 },
      ];
      const lhnumList = [
        { text: '全部楼号', value: '0'},{ text: '1号楼', value: '1'},{ text: '2号楼', value: '2'},{ text: '3号楼', value: '3'},
        { text: '4号楼', value: '4'},{ text: '5号楼', value: '5'},{ text: '6号楼', value: '6'},{ text: '7号楼', value: '7'},
        { text: '7-1号楼', value: '7-1'},{ text: '8号楼', value: '8'},{ text: '9号楼', value: '9'},{ text: '10号楼', value: '10'},
        { text: '11号楼', value: '11'},{ text: '12号楼', value: '12'},{ text: '13号楼', value: '13'},{ text: '14号楼', value: '14'},
        { text: '15号楼', value: '15'},{ text: '16号楼', value: '16'},{ text: '17号楼', value: '17'},{ text: '18号楼', value: '18'},
        { text: '19号楼', value: '19'},{ text: '20号楼', value: '20'},{ text: '21号楼', value: '21'},{ text: '22号楼', value: '22'},
        { text: '23号楼', value: '23'},{ text: '24号楼', value: '24'},{ text: '25号楼', value: '25'},{ text: '26号楼', value: '26'},
        { text: '27号楼', value: '27'},{ text: '28号楼', value: '28'},{ text: '29号楼', value: '29'},{ text: '30号楼', value: '30'},
        { text: '31号楼', value: '31'},{ text: '32号楼', value: '32'},{ text: '33号楼', value: '33'},{ text: '34号楼', value: '34'},
        { text: '35号楼', value: '35'},{ text: '36号楼', value: '36'},{ text: '37号楼', value: '37'},{ text: '38号楼', value: '38'},
        { text: '39号楼', value: '39'},{ text: '40号楼', value: '40'},{ text: '41号楼', value: '41'},{ text: '42号楼', value: '42'},
        { text: '43号楼', value: '43'},{ text: '商铺', value: '44'},{ text: '丰巢柜', value: '45'},
      ];
      const onConfirm = () => {
        itemRef.value.toggle();
        // 或者
        // menuRef.value.close();
        onLoad();
      };

      function changeLocType() {
        onLoad();
      }

      onMounted(() => {
        //getStatistics();
        onLoad();
      });

      //筛选日期
      function onConfirmDate(value) {
        showPicker.value = false;
        let formatDate = (date) => `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
        //console.log('日期：',formatDate(value));
        localDate.value = formatDate(value)
      }

      //搜索
      function onSearch() {
        console.log(localDate.value);
        onLoad();
      }

      //统计数据
      // function getStatistics() {
      //   proxy.$api.get('photos/data', {}, r=>{
      //     statistics.value = r.result;
      //   })
      // }

      //加载数据
      function onLoad() {
        proxy.$api.get('photos/index', {page: currentPage.value, pageSize: 300, buildingNo: buildingNo.value, locType: locType.value, localDate: localDate.value, userId: userId.value, storeId: storeId.value}, r=>{
          let res = r.result;
          shopList.value = res.items;
          currentPage.value = res.currentPage;
          totalItems.value = res.total;
          perPage.value = res.perPage;
          setExpress();
        })
      }

      //设置选中状态
      function setExpress() {
        shopList.value.map((item) => {
          if(item.status == 1){
            if(!shopChecked.value.includes(item.id)){
              shopChecked.value.push(item.id);
            }
          }
        });
      }

      //选择楼号
      function onChangeNum(e) {
        if(e == 7) {
          buildingNo.value = '7-1';
        } else if(e > 7) {
          buildingNo.value = e;
        } else {
          buildingNo.value = e + 1;
        }
        onLoad();
      }

      //翻页
      function changePage(e) {
        currentPage.value = e;
        onLoad();
      }

      //查看图片
      function openImg(url) {
        ImagePreview({
          //images的数据结构是 [ 'yoururl','yoururl',]
          images: [url],
          //closeable展示关闭的小图标
          closeable: true,
        });
        //showImagePreview([url]);
      }

      // 提交表单
      function onSubmit() {
        let arr = [];
        let status = 0;
        shopList.value.map((i) => {
          status = 0;
          shopChecked.value.some((j)=>{
            if(i.id == j){
              status = 1;
              return true
            }
          })
          arr.push({id: i.id, status: status});
        });
        proxy.$api.post('photos/update', {data: arr, buildingNo: buildingNo.value}, r=>{
          let res = r.result;
          //shopChecked.value = res.ids;
          console.log(res);
          Toast('提交成功');
        })
      }

      return {
        active,
        statistics,
        onChangeNum,
        shopList,
        currentPage,
        totalItems,
        perPage,
        changePage,
        lhnum,
        locType,
        lhActive,
        localDate,
        showPicker,
        currentDate,
        minDate: new Date(2023, 0, 1),
        maxDate: new Date(2024, 10, 1),
        openImg,
        onConfirmDate,
        onSearch,
        shopChecked,
        onSubmit,
        menuRef,
        itemRef,
        switch1,
        switch2,
        options,
        onConfirm,
        changeLocType,
        storeId,
        lhnumList,
        buildingNo
      };
    },
  };
</script>
<style scoped>
  .statistics {
    overflow: hidden;
    font-size: 13px;
    text-align: center;
    padding: 10px 0;
    background-color: #e6f7ff;
  }
  .statistics span {
    font-weight: bold;
    display: inline-block;
    margin: 0 5px;
    color: #1989fa;
  }
  .scan-btn {
    padding: 10px 15px;
    background-color: #fff;
    text-align: center;
  }
  .shop-main {
    padding: 0 0 10px;
    overflow: hidden;
    background-color: #fff;
  }
  .shop-main .th {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #f5f6f7;
    line-height: 30px;
  }
  .shop-main .tr {
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    border-bottom: 1px solid #f5f6f7;
  }
  .shop-main .tr:last-child {
    border-bottom: none;
  }
  .shop-main .tr:nth-child(2n-1) {
    background: #fafafa;
  }
  .manual {
    margin-left: 15px;
  }
  .popup-manual {
    border-radius: 3px;
    width: 300px;
    padding: 15px 0;
  }
  .kd-list ul li {
    float: left;
    width: 33.33%;
    margin-bottom: 2%;
  }
  .kd-list ul li .kd-li{
    position: relative;
  }
  .kd-list ul li .kd-li .num {
    position: absolute;
    top: 1%;
    left: 2%;
  }
  .kd-list ul li .kd-li .kd-pic {
    width: 96%;
    margin: 2% 2% 0;
    height: 150px;
  }
  .kd-list ul li .kd-li .nickname {
    position: absolute;
    top: 1%;
    right: 2%;
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;
    word-wrap: break-word;
  }
  .kd-list ul li .kd-li .info {
    position: absolute;
    bottom: 0;
    left: 2%;
    width: 96%;
    background-color: #000;
    opacity: .8;
    text-align: center;
    padding: 3px 0;
    color:#fff;
  }
  .kd-list ul li .kd-li .short-name{
    position: absolute;
    left: 2%;
    bottom: 20px;
  }
  .kd-list ul li .kd-li .building-tag{
    position: absolute;
    right: 2%;
    bottom: 20px;
  }
  .kd-list ul li .box-li{
    margin-top:2%;
    padding: 0 2%;
  }
</style>
