<template>
  <div>
    <van-tabs v-model:active="active" @change="onChangeUser">
      <van-tab title="未通过"></van-tab>
      <van-tab title="已通过"></van-tab>
    </van-tabs>
    <div class="staff-m" v-for="item in staffList" :key="item.id">
      <div class="s-m-l">
        <img :src="item.avatar" width="50" height="50" />
      </div>
      <div class="s-m-r">
        <div class="nickname">
          {{ item.nickname }}<template v-if="item.remark">（{{ item.remark }}）</template>
          <span>状态：<template v-if="item.status == 1"><em class="red">通过</em></template><template v-else>未通过</template><van-tag type="success" v-if="item.merchantId == 1">{{ item.fullStatus == 1 ? '全职':'兼职' }}</van-tag></span>
          <van-button type="primary" size="mini" @click="examine(item)" class="right">审核</van-button>
        </div>
        <div class="shop">
          驿站状态：<span><template v-if="item.shopStatus == 1"><em class="red">通过</em></template><template v-else>未通过</template></span>
          <span v-for="shop in item.shopData" :key="shop.id" class="shop-i">{{ shop.shop.shortName }}</span>
        </div>
        <div class="group">团购状态：<span><template v-if="item.groupStatus == 1"><em class="red">通过</em></template><template v-else>未通过</template></span></div>
      </div>
    </div>

    <!-- 底部弹出 -->
    <van-popup
      v-model:show="showBottom"
      position="bottom"
      :style="{ height: '50%' }"
    >
    <van-form @submit="onSubmit">
      <van-field name="merchantId" label="所属商户">
        <template #input>
          <van-radio-group v-model="staffInfo.merchantId" direction="horizontal">
            <van-radio name="1">一秒到家</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="status" label="会员状态">
        <template #input>
          <van-radio-group v-model="staffInfo.status" direction="horizontal">
            <van-radio name="1">开启</van-radio>
            <van-radio name="0">关闭</van-radio> 
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="shopStatus" label="驿站状态">
        <template #input>
          <van-radio-group v-model="staffInfo.shopStatus" direction="horizontal">
            <van-radio name="1">开启</van-radio>
            <van-radio name="0">关闭</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="shopList" label="驿站选择">
        <template #input>
          <van-checkbox-group v-model="staffInfo.shopList" direction="horizontal">
            <van-checkbox v-for="shop in shops" :key="shop.id" :name="String(shop.id)" shape="square">{{ shop.shortName }}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field name="groupStatus" label="团购状态">
        <template #input>
          <van-radio-group v-model="staffInfo.groupStatus" direction="horizontal">
            <van-radio name="1">开启</van-radio>
            <van-radio name="0">关闭</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="fullStatus" label="工作状态">
        <template #input>
          <van-radio-group v-model="staffInfo.fullStatus" direction="horizontal">
            <van-radio name="1">全职</van-radio>
            <van-radio name="0">兼职</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    </van-popup>
    <WxNoShare />
  </div>
</template>

<script>
  import { ref, onMounted, getCurrentInstance } from 'vue';
  import { useStore } from 'vuex'
  import { Grid, GridItem, Popup, RadioGroup, Radio, Form, Field, CellGroup, Checkbox, CheckboxGroup, Tab, Tabs, Tag } from 'vant';

  export default {
    components: {
      [Grid.name]: Grid,
      [GridItem.name]: GridItem,
      [Popup.name]: Popup,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Form.name]: Form,
      [Field.name]: Field,
      [CellGroup.name]: CellGroup,
      [Checkbox.name]: Checkbox,
      [CheckboxGroup.name]: CheckboxGroup,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Tag.name]: Tag,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const store = useStore();
      const userInfo = ref(store.state);
      const staffList = ref([]);
      const active = ref(0);
      const showBottom = ref(false);
      const staffInfo = ref({id: 0, merchantId: '0', status: '0', shopStatus: '0', groupStatus: '0', fullStatus: '0', shopList: []});
      const groupChecked = ref([]);
      const shops = ref([]);

      onMounted(() => {
        // console.log(userInfo.value);
        getShop();
        onLoad();
      });

      //加载数据
      function getShop() {
        proxy.$api.get('members/shop', {}, r=>{
          shops.value = r.result;
        })
      }

      //选择类型
      function onChangeUser(e) {
        active.value = e;
        onLoad();
      }

      //加载数据
      function onLoad() {
        proxy.$api.get('members/staff', { status: active.value }, r=>{
          let res = r.result;
          staffList.value = res.items;
        })
      }

      //审核
      function examine(item) {
        staffInfo.value.shopList = [];
        showBottom.value = true;
        staffInfo.value.id = item.id;
        staffInfo.value.merchantId = String(item.merchantId);
        staffInfo.value.status = String(item.status);
        staffInfo.value.shopStatus = String(item.shopStatus);
        staffInfo.value.groupStatus = String(item.groupStatus);
        staffInfo.value.fullStatus = String(item.fullStatus);
        item.shopList.map((shop)=>{
          staffInfo.value.shopList.push(String(shop));
        });
      }

      function onSubmit(values) {
        proxy.$api.post('members/examine', {id: staffInfo.value.id, type: 0, ...values}, r=>{
          let res = r.result;
          console.log(res);
          showBottom.value = false;
          onLoad();
        })
      }
 
      return {
        userInfo,
        staffList,
        examine,
        active,
        onChangeUser,
        showBottom,
        staffInfo,
        onSubmit,
        groupChecked,
        shops
      };
    },
  };
</script>
<style scoped>
  .staff-m{
    overflow: hidden;
    margin: 10px;
    background: #fff;
    padding:5px;
    font-size: 13px;
  }
  .staff-m em{
    font-style: normal;
  }
  .staff-m .s-m-l{
    width: 15%;
    float: left;
  }
  .staff-m .s-m-r{
    width: 85%;
    float: right;
  }
  .staff-m .s-m-r .nickname span{
    display: inline-block;
    margin: 0 10px;
  }
  .red{
    color:red;
  }
  .staff-m .s-m-r .shop .shop-i{
    margin: 0 5px;
  }
</style>
