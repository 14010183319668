<template>
  <div v-show="show">
    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="smile-o">
      全部上门合计总数量：{{ statistics.allSumCount }} 件
    </van-notice-bar>
    <van-tabs v-model:active="active">
      <van-tab title="个人统计">
        <div class="st-search">
          <van-cell :title="statistics.date" value="筛选" is-link />
        </div>
        <div class="st-top">
          <van-row>
            <van-col span="12" class="center">驿站上门：<span>{{ statistics.photoCount }}</span>件</van-col>
            <van-col span="12" class="center">团购上门：<span>{{ statistics.groupCount }}</span>件</van-col>
          </van-row>
          <div class="st-hj">总共合计：<span>{{ statistics.sumCount }}</span>件</div>
        </div>
        <van-cell-group title="驿站上门">
          <van-cell v-for="store in statistics.stores" :key="store.id" :title="store.shortName" :value="store.count+`件`" is-link />
        </van-cell-group>
        <van-cell-group title="团购上门">
          <van-cell v-for="store in statistics.groups" :key="store.id" :title="store.shortName" :value="store.count+`件`" is-link />
        </van-cell-group>
      </van-tab>
      <van-tab title="排行榜">
        <div class="ranking">
          <van-row v-for="(item, index) in statistics.ranking" :key="item.id">
            <van-col span="3" class="pm">{{ index+1 }}</van-col>
            <van-col span="5"><img :src="item.avatar" height="40" width="40" /></van-col>
            <van-col span="10">{{ item.nickname }}</van-col>
            <van-col span="6">{{ item.count }}件</van-col>
          </van-row>
        </div>
      </van-tab>
    </van-tabs>
    <WxNoShare />
  </div>
</template>

<script>
  import { ref, getCurrentInstance, onMounted } from 'vue';
  import { Tab, Tabs, Cell, CellGroup, NoticeBar } from 'vant'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router' 

  export default {
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [NoticeBar.name]: NoticeBar,
    },
    setup() {
      const active = ref(0);
      const show = ref(true);
      const { proxy } = getCurrentInstance();
      const store = useStore();
      const route = useRoute()
      const openId = route.query.o
      const statistics = ref({sumCount: 0, groupCount: 0, photoCount: 0, allSumCount: 0});
      const localDate = ref('');

      onMounted(() => {
        show.value = true;
        getData();
      });

      function getData(){
        proxy.$api.get('statistics/runErrands', {o: openId, localDate: localDate.value}, r=>{
          statistics.value = r.result
        })
      }

      return {
        show,
        active,
        store,
        getData,
        statistics
      };
    },
  };
</script>

<style scoped>
  .st-search{
  }
  .st-top{
    font-size: 14px;
    background: #fff;
    margin-top: 10px;
    padding: 10px 0;
  }
  .st-top .center{
    padding: 0 10px;
  }
  .st-top .st-hj{
    margin-top:10px;
    padding: 0 10px;
    font-weight: bold;
  }
  .st-top span{
    margin: 0 3px;
  }
  .st-top .st-hj span{
    color:red;
  }
  .ranking{
    line-height: 40px;
    background: #fff;
    overflow: hidden;
  }
  .ranking .pm{
    text-align: center;
  }
</style>
