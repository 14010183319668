<template>
  <div class="main">
    <div class="login-title">
      <h3>一秒跑腿平台</h3>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="username"
          name="username"
          label="手机号"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <WxNoShare />
  </div>
</template>

<script>
  import { ref, getCurrentInstance } from 'vue';
  import { Form, Field, CellGroup, Toast } from 'vant'
  import { useStore } from 'vuex'
  import { useRouter } from "vue-router";

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [CellGroup.name]: CellGroup,
    },
    setup() {
      const username = ref('');
      const password = ref('');
      const { proxy } = getCurrentInstance();
      const store = useStore();
      const router = useRouter();

      const onSubmit = (values) => {
        proxy.$api.post('auth/login', {...values}, r=>{
          if (r.code == 200) {
            store.dispatch('logined', r.result);
            router.push({
              name: 'home',
            });
          } else {
            Toast('账号或密码错误！');
          }
        })
      };

      return {
        username,
        password,
        onSubmit,
      };
    },
  };
</script>

<style scoped>
  .main {
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    height: 100%;
    width: 100%;
    padding-top: 100px;
  }
  .login-title {
    overflow: hidden;
  }
  .login-title h3{
    text-align: center;
    font-size: 22px;
  }
</style>
