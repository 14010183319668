import { createApp } from 'vue'
import App from './App.vue'
import { Button, Tabbar, TabbarItem, Toast, Col, Row, Field, Icon, Lazyload, DatetimePicker } from 'vant'
import 'vant/lib/index.css'
import router from './router'
import 'lib-flexible/flexible'
import store from './store'
import './assets/css/style.css'
import './assets/iconfont/iconfont.js'
import api from './api'
import axios from 'axios'
import common from './common'
import Footer from '@/components/common/footer.vue'
import Floating from '@/components/common/floating.vue'
import WxNoShare from '@/components/wxjsapi/noShare.vue'

const app = createApp(App).use(router).use(store); // 创建实例

app.config.globalProperties.$api = api;
app.config.globalProperties.common = common;
app.use(Button);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Toast);
app.use(Col);
app.use(Row);
app.use(Field);
app.use(Icon);
app.use(Lazyload);
app.use(router);
app.use(DatetimePicker);

// 全局自定义组件
app.component("Footer", Footer);
app.component("Floating", Floating);
app.component("WxNoShare", WxNoShare);

app.mount('#app');

// 使用router.beforeEach注册一个全局前置守卫,判断用户是否登录
router.beforeEach((to, from, next) => {
    let redirectUrl = process.env.VUE_APP_M_ACCOUNT_URL + to.href;
    let code = getUrlParam("code");
    if (to.meta.requireAuth) {
        if (store.state.auth) {
            next();
        } else {
            if(process.env.VUE_APP_NODE_ENV === 'production') {
                // 微信授权登录
                if(!code){
                    sessionStorage.setItem('redirectPath', redirectUrl);
                    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + process.env.VUE_APP_WX_APPID + "&redirect_uri=" + redirectUrl + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
                }
                let authUrl = process.env.VUE_APP_ADMIN_API_URL + 'socials/wechat/authorizations?code=' + code
                axios.post(authUrl).then(function (res) {
                    let data = res.data
                    if (data.code === 200) {
                        store.dispatch('logined', data.result);

                        // 获取用户
                        axios.get(process.env.VUE_APP_ADMIN_API_URL + 'account/info', {}).then(res => {
                            let r = res.data;
                            if (r.code == 200) {
                                store.commit('PROFILE', r.result);
                                // 重定向页面
                                let redirectPath = sessionStorage.getItem('redirectPath');
                                if (redirectPath) {
                                    sessionStorage.removeItem('redirectPath');
                                    window.location.href = redirectPath;
                                } else {
                                    next()
                                }
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });
                        
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                window.location.href = '/login';
            }     
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    document.title = to.meta.title || ""
})

// 解析url参数并获取code
function getUrlParam(name) {
    //name为要获取的参数名
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var rrr = decodeURIComponent(window.location.search);
    var r = rrr.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
